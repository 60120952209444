@import "bootstrap/scss/bootstrap";

/* SlotCard.css */
.card-body {
  display: flex;
  flex-direction: column;
}

.mt-auto {
  margin-top: auto;
}

.card {
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.card:hover {
  transform: scale(1.02);
}

.card-img-top {
  border-bottom: 1px solid #ddd;
}

.card-title {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.card-footer {
  background-color: #f8f9fa;
  border-top: 1px solid #ddd;
}

.modal-content {
  border-radius: 10px;
}

.modal-header {
  background-color: #007bff;
  color: white;
  border-bottom: none;
}

.modal-footer {
  border-top: none;
}

.provider-bio {
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 5px;
  line-height: 1.6;
}

.modal-body p {
  margin-bottom: 0;
}

